<template>
  <div>
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>追求优秀的工程师文化</div>
          <div>信仰技术，认同科技，以我们的价值观为动力</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section
        class="container-lg px-4 d-flex flex-column justify-content-center"
      >
        <div class="text-center lh-lg">
          <div class="fs-3 d-lg-block d-none">
            <div>我们的核心价值观</div>
            <div>这些价值观不仅仅是文字，而是我们文化的体现，也是我们衡量成功的标准。</div>
          </div>
          <div class="fs-5 d-lg-none">
            <div>我们的核心价值观</div>
            <div>这些价值观不仅仅是文字，而是我们文化的体现，也是我们衡量成功的标准。</div>
          </div>
        </div>
      </section>
      <section class="bg-light value">
        <div class="innovation">
          <div class="title">创新</div>
          <div class="content">
            <div class="title">创新</div>
            我们是永不满足的创新者，不断挖掘A股市场的超额收益；我们热情地追求新想法、新技术和新战略，以增强自身的竞争优势。
          </div>
        </div>
        <div class="efficiency">
          <div class="title">高效</div>
          <div class="content">
            <div class="title">高效</div>
            我们是 Rust 先行者，追求高效与极致；我们聘请优秀的人，采用先进的技术，建立卓越的团队，成就不平凡的非凸，为行业带来效率价值最大化。
          </div>
        </div>
        <div class="self-driven">
          <div class="title">自驱</div>
          <div class="content">
            <div class="title">自驱</div>
            我们倡导自驱力，因为兴趣会让你迸发出真正的动力；我们有超越竞争对手的思维和执行力，在技术迭代中不断发掘无限可能。
          </div>
        </div>
        <div class="agile">
          <div class="title">敏捷</div>
          <div class="content">
            <div class="title">敏捷</div>
            我们的开发团队小而敏，能够快速开发、快速上线、快速迭代；我们汇聚了聪明的头脑、敏捷的行动力，以应对瞬息万变的金融市场。
          </div>
        </div>
        <div class="tolerant">
          <div class="title">包容</div>
          <div class="content">
            <div class="title">包容</div>
            我们拥抱大胆的想法，鼓励大家敢于尝试、勇于创新；我们寻求多元化的团队，有着相同的价值观，懂得协作解决问题。
          </div>
        </div>
        <div class="share">
          <div class="title">共享</div>
          <div class="content">
            <div class="title">共享</div>
            我们提供信息共享平台，每个员工都可以平等获取有用信息；我们鼓励相互交流，倡导共享文化，把每个人的优势发挥到最大化。
          </div>
        </div>
      </section>
      <section>
        <div class="container-lg px-4 py-5 text-center">
          <div class="row fs-1 fw-bold d-inline-block mb-5 ms-1">
            文化福利
          </div>
          <div class="row row-cols-1 row-cols-md-3 pt-3 gx-5">
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/保持学习.png" alt="保持学习">
                </div>
                <div class="col-9 lh-lg">
                  <div class="fs-5 fw-bold">保持学习</div>
                  <div class="fs-6 text-muted">
                    新人培训、职业发展和领导力培养，帮你不断进步，为职场升级打怪加满血槽。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/谈天说地.png" alt="谈天说地">
                </div>
                <div class="col-9 lh-lg">
                  <div class="fs-5 fw-bold">谈天说地</div>
                  <div class="fs-6 text-muted">
                    听资深前辈分享行业洞见和技术前沿，听同事分享工作体验和生活感悟，收获成长。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/弹性工作.png" alt="弹性工作">
                </div>
                <div class="col-9 lh-lg">
                  <div class="fs-5 fw-bold">弹性工作</div>
                  <div class="fs-6 text-muted">
                    上下班不打卡，让你上班路上更淡定；灵活的调休机制，让你更好的安排生活。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/健康生活.png" alt="健康生活">
                </div>
                <div class="col-9 px-5 py-3">
                  <div class="fs-5 fw-bold">健康生活</div>
                  <div class="fs-6 text-muted">
                    面对不确定的明天，安排定期体检及全面保险，让你工作生活更安心。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/假期福利.png" alt="假期福利">
                </div>
                <div class="col-9 lh-lg">
                  <div class="fs-5 fw-bold">假期福利</div>
                  <div class="fs-6 text-muted">
                    年假、病假、事假、产假、陪产假、婚假......各种类型的带薪休假，一应俱全。
                  </div>
                </div>
              </div>
            </div>
            <div class="col square px-5 py-3">
              <div class="row gx-5 row-cols-2 row-cols-lg-1">
                <div class="col-3 my-3 p-0">
                  <img class="culture-icon" src="../../assets/img/culture/能量加油站.png" alt="能量加油站">
                </div>
                <div class="col-9 lh-lg">
                  <div class="fs-5 fw-bold">能量加油站</div>
                  <div class="fs-6 text-muted">
                    网红零食，健康小吃，饮料茶水，咖啡吧台，补充你一天的能量，元气满满。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="pt-5 life" style="background-color: #333333;">
        <div class="text-center lh-lg text-light my-5">
          <div class="fs-1 fw-bold">非凸生活</div>
          <div class="text-muted">欢迎加入非凸大家庭，一起热爱工作，热爱生活！</div>
          <router-link
            role="button"
            to="/join"
            class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            >今天申请</router-link
          >
        </div>
        <div class="life-grid row row-cols-2 row-cols-md-4 pt-5 mt-5 gx-0">
          <div class="col">
            <div class="grid-container grid-01 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">定期组织团建</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-02 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">偶尔休闲娱乐</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-03 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">节日气氛满分</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-04 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">日常下午茶</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-05 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">开放式办公位</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-06 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">一起手撕难题</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-07 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">聊聊工作谈谈心</div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="grid-container grid-08 ratio ratio-1x1">
              <div class="mask"></div>
              <div class="content">
                <div class="title">大佬推荐书单</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Culture",
  data() {
    return {

    }
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>

.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/culture/banner.jpg");
  background-position-y: 40%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.value {
  &>div {
    height: 250px;
    position: relative;
    cursor: default;
    background-size: cover;
    background-position: center;
    &>.title {
      color: #fff;
      height: 100%;
      width:100%;
      font-size: 2.75rem;
      text-align: center;
      padding-top: 5rem;
      background-color: black;
      opacity: 0.5;
      transition: 0.2s;
    }
    &:hover>.title {
      opacity: 0;
    }
    &:hover>.content {
      opacity: 0.8;
    }
    &>.content {
      position: absolute;
      top: 0;
      color: #fff;
      height: 100%;
      width:100%;
      text-align: center;
      padding: 5rem;
      background-color: $--ft-primary-5;
      opacity: 0;
      // display: none;
      transition: 0.2s;
      &>.title {
        font-size: 2.75rem;
      }
    }
  }
  &>.innovation {
    background-image: url("../../assets/img/culture/01.jpg");
    background-position-x: 70%;
  }
  &>.efficiency {
    background-image: url("../../assets/img/culture/02.jpg");
  }
  &>.self-driven {
    background-image: url("../../assets/img/culture/03.jpg");
  }
  &>.agile {
    background-image: url("../../assets/img/culture/04.jpg");
  }
  &>.tolerant {
    background-image: url("../../assets/img/culture/05.jpg");
  }
  &>.share {
    background-image: url("../../assets/img/culture/06.jpg");
  }
}
.culture-icon {
  width: 5rem;
  height: 5rem;
}
.life {
  .life-grid {
    &>div {
      position: relative;
      cursor: default;
      background-size: cover;
      background-position: center;
    }
    .grid-container {
      background-size: cover;
      &:hover>.content {
        opacity: 0.8;
      }
      &>.content {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #fff;
        height: 100%;
        width:100%;
        text-align: center;
        background-color: $--ft-primary-5;
        opacity: 0;
        // display: none;
        transition: 0.2s;
        &>.title {
          font-size: 2rem;
        }
      }
      &>.mask {
        background-color: #000;
        opacity: 0.2;
      }
    }
    .grid-01 {
      background-image: url("../../assets/img/culture/01-定期组织团建.jpg");
    }
    .grid-02 {
      background-image: url("../../assets/img/culture/02-偶尔休闲娱乐.jpg");
    }
    .grid-03 {
      background-image: url("../../assets/img/culture/03-节日气氛满分.jpg");
    }
    .grid-04 {
      background-image: url("../../assets/img/culture/04-日常下午茶.jpg");
    }
    .grid-05 {
      background-image: url("../../assets/img/culture/05-开放式办公位.jpg");
    }
    .grid-06 {
      background-image: url("../../assets/img/culture/06-一起手撕难题.jpg");
    }
    .grid-07 {
      background-image: url("../../assets/img/culture/07-聊聊工作谈谈心.jpg");
    }
    .grid-08 {
      background-image: url("../../assets/img/culture/08-大佬推荐书单.jpg");
    }
  }
}


@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.25rem;
    text-align: center;
  }
  .jumbotron-wrapper.image {
    background-position-x: 25%;
  }
  .cover-text > .btn {
    margin: auto;
    display: block;
    width: 7rem;
  }
  .value {
    &>div {
      &>.content {
        padding: 2rem 3rem;
        &>.title {
          margin-bottom: 1rem;
        }
      }
    }
  }
  .life {
    .life-grid {
      .grid-container {
        &>.content {
          &>.title {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
